
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import { carrierMatch } from '@/utils/carrier'
import ZButton from '@/components/base/z-button.vue'
import { Loading } from 'element-ui'
import { chunk } from 'lodash'

@Component({
	components: { ZButton },
	beforeRouteLeave(to: any, from: any, next: any) {
		this.$confirm('确认离开当前页面？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				next()
			})
			.catch(() => {
				next(false)
			})
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}

	private inputCodeStr = ''
	private orderingResult: any = {}
	private orderingValidateResult: any = {}
	private isLoading = false
	private codesList: any = []
	private editForm = {
		channel: ''
	}

	private channels: any = []
	private validateForm = {
		channel: [{ required: true, message: '请选择排货渠道', trigger: 'change' }]
	}
	async parseCode() {
		if (!this.inputCodeStr.trim()) return ''
		const codes: string[] = Array.from(
			new Set(
				this.inputCodeStr
					.trim()
					.split(/[;,\s]/)
					.filter(item => {
						if (item.trim()) return item.trim()
					})
			)
		)
		this.inputCodeStr = ''
		const codesList = this.codesList.map((item: any) => item.code)
		const loadingInstance = Loading.service({
			fullscreen: true,
			text: '加载中',
			background: 'rgba(0, 0, 0, 0.8)'
		})
		const queryCodeRes = await this.queryCodes(codes)
		this.$nextTick(() => {
			loadingInstance.close()
		})
		for (const code in queryCodeRes) {
			if (codesList.includes(code)) continue
			this.codesList.push({
				code,
				is_edited: false,
				is_loading: false,
				name_cn: '',
				...queryCodeRes[code]
			})
			this.$set(
				this.orderingResult,
				code,
				queryCodeRes[code]['tracking_number_result']
			)
		}
	}
	emitRowInputCode($index: number, row: any, codeName: string) {
		const carrier = carrierMatch(row[codeName])
		if (carrier) {
			this.codesList[$index][codeName.replace(/code/, 'company')] =
				carrier.value
		}
	}

	created() {
		this.getChannels()
	}
	formatUserRole(role: number) {
		if (role === 1) {
			return {
				label: '电商商家',
				type: 'success'
			}
		}
		if (role === 2) {
			return {
				label: '格布员工',
				type: 'danger'
			}
		}

		return {
			label: '个人客户',
			type: 'info'
		}
	}
	async queryCodes(codes: string[]) {
		const { data } = await this.$axios.post(
			'v1/jobline/ordering/package-code/search',
			{
				codes
			}
		)

		const codesMap: any = {}
		data.forEach((item: any) => {
			codesMap[item.code] = item
			this.$set(this.orderingResult, item.code, item.result)
		})
		return codesMap
	}
	async getChannels() {
		const { data } = await this.$axios.get('v1/jobline/ordering/channels', {})
		this.channels = data
	}
	async removePackage({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList.splice($index, 1)
	}

	getResult(code: string) {
		return this.orderingResult[code] || null
	}
	getValidateResult(packageNum: string) {
		return this.orderingValidateResult[packageNum] || null
	}

	getRowClassName({ row, $index }: any) {
		const orderingResult = this.getResult(row.code)
		if (!orderingResult) return ''
		return orderingResult.code === 200 ? '' : 'ordering-fail'
	}

	async getOrderingResult(packageAttrNum: string) {
		const { data } = await this.$axios.get(
			`v1/jobline/ordering/${packageAttrNum}/get-tracking-number-result`,
			{}
		)
		return data
	}

	async emptyPackages() {
		const isConfirm = await this.$confirm('确认清空？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList = []
	}

	async doFetchTackingNumber(row: any) {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { channel } = this.editForm
		if (row.is_loading) return
		row.is_loading = true

		await this.$axios
			.get('v1/jobline/ordering/package/get-tracking-number', {
				params: {
					package_attr_num: row.code,
					channel: channel
				}
			})
			.finally(() => {
				row.is_loading = false
			})
		const resultData = await this.getOrderingResult(row.code)
		this.$set(this.orderingResult, row.code, resultData.result)
		row.platform_code = resultData.platform_code
		await this.doFetchPackageLabel(row)
		row.express_code = resultData.express_code
		row.express_company = resultData.express_company
	}
	async doFetchPackageLabel(row: any) {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { channel } = this.editForm
		if (row.is_loading) return
		row.is_loading = true

		const { data } = await this.$axios
			.get('v1/jobline/ordering/package/get-label', {
				params: {
					package_attr_num: row.code,
					channel: channel
				}
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => {
				row.is_loading = false
			})
		if (data && data.url) {
			row.has_platform_label = 1
		}
	}
	async doBatchFetchTackingNumber() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isConfirm = await this.$confirm('确认获取？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const shipmentChunks = chunk(this.codesList, 2)
		for (const items of shipmentChunks) {
			await Promise.all(
				items.map(async (item: any) => {
					if (!item.express_code) {
						await this.doFetchTackingNumber(item)
					}
				})
			)
		}
		this.$message.success('重试完成')
	}
	async doBatchReFetchTackingNumber() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isConfirm = await this.$confirm('确认获取？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const shipmentChunks = chunk(this.codesList, 2)
		for (const items of shipmentChunks) {
			await Promise.all(
				items.map(async (item: any) => {
					await this.doFetchTackingNumber(item)
				})
			)
		}
	}
}
