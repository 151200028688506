var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/ops/ordering" } } },
            [_vm._v("排货出库")]
          ),
          _c("el-breadcrumb-item", [_vm._v("获取单号")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      rules: _vm.validateForm,
                      model: _vm.editForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "排货渠道", prop: "channel" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择渠道" },
                            model: {
                              value: _vm.editForm.channel,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "channel", $$v)
                              },
                              expression: "editForm.channel",
                            },
                          },
                          _vm._l(_vm.channels, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.code,
                                attrs: { label: item.name, value: item.code },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "包裹号/箱号", prop: "codes" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "多个单号用英文逗号 , 分隔",
                            rows: 2,
                          },
                          on: { blur: _vm.parseCode },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.parseCode.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.inputCodeStr,
                            callback: function ($$v) {
                              _vm.inputCodeStr = $$v
                            },
                            expression: "inputCodeStr",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.codesList,
                      "row-class-name": _vm.getRowClassName,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "code", label: "包裹单号", width: "150" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "express_title",
                        width: "150",
                        label: "线路",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "客户类型", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: _vm.formatUserRole(
                                      scope.row.user_role
                                    ).type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUserRole(scope.row.user_role)
                                        .label
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "收件人信息",
                        "min-width": "300",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [
                                _vm._v(
                                  _vm._s(scope.row.firstname) +
                                    " " +
                                    _vm._s(scope.row.last_name)
                                ),
                              ]),
                              _c("p", [_vm._v(_vm._s(scope.row.mobile))]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.address1) +
                                    " " +
                                    _vm._s(scope.row.address2 || "") +
                                    ", " +
                                    _vm._s(scope.row.city) +
                                    ", " +
                                    _vm._s(scope.row.state) +
                                    " " +
                                    _vm._s(scope.row.zipcode) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "包裹尺寸", width: "150px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.length
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(
                                        " 尺寸：" +
                                          _vm._s(scope.row.length) +
                                          " * " +
                                          _vm._s(scope.row.width) +
                                          " * " +
                                          _vm._s(scope.row.height) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "重量： " +
                                          _vm._s(
                                            (scope.row.weight / 1000).toFixed(3)
                                          ) +
                                          "kg"
                                      ),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "logistics_code",
                        width: "180",
                        label: "入仓单号",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "渠道商单号",
                        prop: "platform_code",
                        "min-width": "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.platform_code
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.platform_code)),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "末端承运商",
                        prop: "express_company",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.express_company
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.express_company)),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "末端单号",
                        "min-width": "150",
                        prop: "express_code",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.express_code
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.express_code)),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "是否有面单",
                        "min-width": "100",
                        prop: "has_platform_label",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: scope.row.has_platform_label
                                      ? "success"
                                      : "info",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.has_platform_label ? "是" : "否"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "服务商响应",
                        width: "250px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: scope.row.is_loading,
                                      expression: "scope.row.is_loading",
                                    },
                                  ],
                                },
                                [
                                  _vm.getResult(scope.row.code)
                                    ? _c(
                                        "p",
                                        [
                                          _vm.getResult(scope.row.code).code ===
                                          200
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "success",
                                                    size: "mini",
                                                  },
                                                },
                                                [_vm._v("成功")]
                                              )
                                            : _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    size: "mini",
                                                  },
                                                },
                                                [_vm._v("失败")]
                                              ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getResult(scope.row.code)
                                                  .message
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("p", [_vm._v("-")]),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", fixed: "right", width: "230" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button-group",
                                [
                                  _c(
                                    "z-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doFetchTackingNumber(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-refresh",
                                      }),
                                      _vm._v("重新获取"),
                                    ]
                                  ),
                                  _c(
                                    "z-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removePackage(scope)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                      }),
                                      _vm._v("删除"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.codesList.length
            ? _c(
                "div",
                { staticClass: "ctrl-btn" },
                [
                  _c(
                    "z-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.isLoading },
                      on: { click: _vm.doBatchFetchTackingNumber },
                    },
                    [_vm._v(_vm._s(_vm.isLoading ? "获取中" : "立即获取"))]
                  ),
                  _c(
                    "z-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.isLoading },
                      on: { click: _vm.doBatchReFetchTackingNumber },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.isLoading ? "获取中" : "清除单号并获取")
                      ),
                    ]
                  ),
                  _c(
                    "z-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.emptyPackages },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }